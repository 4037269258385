/* General Navbar Styles */
.nav-container {
    position: fixed;
    width: 100%;
    z-index: 1000;
    top: 0;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #1c0c2e;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.navbar-logo img {
    width: 100px;
    margin: 0 15px;
    border-radius: 10px;
}

/* Desktop Navbar */
.navbar-desktop .navbar-links {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
}

.navbar-desktop .navbar-links li {
    margin: 0 15px;
}

.navbar-desktop .navbar-links a {
    text-decoration: none;
    color: white;
    transition: color 0.3s ease;
}

.navbar-desktop .navbar-links a:hover {
    color: #6c377b;
}

/* Hamburger Menu Icon for Mobile */
.hamburger-menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 1010;
}

.hamburger-menu-icon span {
    height: 3px;
    width: 25px;
    background-color: white;
    margin: 5px 0;
    transition: all 0.3s ease-in-out;
}

/* Mobile Menu */
.navbar-links-mobile {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 60px; /* Below the navbar */
    left: 0;
    width: 100%;
    background-color: #1c0c2e;
    height: auto; /* Adjust to fit content */
    padding: 20px 0; /* Add padding */
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    transform: translateY(-100%);
    transition: transform 0.3s ease;
}

.navbar-links-mobile.open {
    transform: translateY(0);
    display: flex;
}

/* Mobile Navbar Logo at the Top */
.mobile-navbar-logo img {
    width: 100px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.navbar-links-mobile li {
    margin: 10px 0;
}

.navbar-links-mobile a {
    font-size: 1.2rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.navbar-links-mobile a:hover {
    color: #6c377b;
}

/* Media Queries for Mobile */
@media only screen and (max-width: 768px) {
    .navbar-desktop {
        display: none; /* Hide desktop navbar on mobile */
    }

    .hamburger-menu-icon {
        display: flex; /* Show hamburger menu icon on mobile */
    }

    .navbar-logo img {
        display: none; /* Hide logo on mobile version, except in mobile menu */
    }
}
