.source-code-pro {
    font-family: "Source Code Pro", monospace;
    font-optical-sizing: auto;
    font-style: normal;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

canvas {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Sends the canvas behind other content */
    background-attachment: fixed;
}
