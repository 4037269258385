html,
body {
    height: 100%;
}



body {
    position: relative;
    font-family: "Roboto Mono", "Dosis", "Orbitron", "Source Code Pro",
        monospace;
    color: white;
}

canvas {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%; /* Extends the canvas height beyond just the viewport */
    z-index: -1; /* Sends the canvas behind the content */
}

.navbar {
    background: linear-gradient(90deg, #5270ff, #fe66c4) !important;
}


.nav-container {
    position: fixed;
    width: 100%;
    z-index: 1000;
    margin-bottom: 20px;
}

.navbar-logo img {
    width: 100px;
    margin: 0 auto;
    border-radius: 10px;
    margin-left: 0;
    padding-left: 0;
}

.navbar {
    display: flex;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    justify-content: center !important;
    align-items: center;
    padding: 20px;
    font-family: "Roboto Mono";
    color: white;
    background-color: #1c0c2e; /* Background to make the navbar visible while scrolling */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.navbar-links {
    display: flex;
    list-style: none;
    text-decoration: none;
    color: white;
    font-family: "Roboto Mono", monospace;
}

ul.navbar-links {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.navbar-links li {
    padding: 0px 20px;
    margin: 20px 15px;
}

.navbar-links a {
    text-decoration: none;
    color: white;
    transition: color 150ms ease-in-out; /* Smooth color transition */
}

.navbar-links a:hover {
    color: #6c377b; /* Apply hover effect to the <a> tag */
    cursor: pointer;
}

.location-map {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: 400px;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 50px;
}

img.location-image {
    display: block;
    margin: 0 auto;
    max-width: 75%;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
}

button.GoogleMaps {
    padding: 10px 20px;
    background-color: #6c377b;
    border-radius: 50px;
    margin-top: 10px; /* Bring button even closer to the content */
    margin-bottom: 30px; /* Add a bit of space before the about section */
    color: white;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.GoogleMaps a {
    text-decoration: none;
    color: white;
}

.GoogleMaps:hover {
    background-color: #411b6b;
}

/* Hero Section */
header {
    height: 100vh;
}

.hero {
    text-align: center;
    padding: 150px 0px; /* Reduce padding further to bring content higher */
    font-family: "Orbitron";
    color: white;
    display: block;
    margin: 0 auto;
    max-width: 800px;
}

.hero h1 {
    font-size: 2.5rem; /* Reduce font size to fit more content in the viewport */
    margin-bottom: 15px;
    font-family: "Orbitron", monospace;
}

.hero p {
    font-size: 1.2rem; /* Reduce font size for better fit */
    margin-bottom: 30px;
    font-family: "Roboto Mono", monospace;
}

.hero .discover-btn {
    padding: 10px 20px;
    background-color: #6c377b;
    border-radius: 50px;
    margin-top: 10px; /* Bring button even closer to the content */
    margin-bottom: 30px; /* Add a bit of space before the about section */
    color: white;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.discover-btn a {
    text-decoration: none;
    color: white;
}

.hero .discover-btn:hover {
    background-color: #411b6b;
}

/* About Section */
.about {
    max-width: 50%;
    display: block;
    margin: 60px auto;
    padding: 10px;
    background-color: transparent; /* Keeps the gradient background */
    font-size: 1.5rem;
    font-family: "Dosis", monospace;
    color: white;
    text-align: center;
}

.about h2 {
    font-family: "Roboto Mono", monospace;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px; /* Add some space between heading and paragraph */
}

.about p {
    margin-top: 20px;
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 30px;
}

.gallery {
    padding: 50px;
    background-color: transparent; /* Keeps the gradient background */
    color: white;
}

.gallery h2 {
    font-family: "Roboto Mono", monospace;
    font-size: 2rem;
    text-align: center;
}

footer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Dosis", monospace;
    font-size: 1.5rem;
    margin: 10px;
    padding: 10px;
    text-align: center;
    color: white;
}

footer ul {
    list-style: none;
    text-align: center;
    margin-left: 0;
    padding-left: 0;
    color: #6c377b;
}

.socials,
.contact,
.legal {
    margin: 10px 30px;
}

.socials a {
    color: #411b6b;

    text-decoration: none;
    margin: 0 10px;
}

.contact {
    max-width: 20%;
}

.credits {
    color: #411b6b;
    font-size: 1rem;
    text-align: center;
    font-family: "Source Code Pro", monospace;
    margin-bottom: 10px;
    margin-top: 20px;
}

.credits a {
    color: #411b6b;
    text-align: center;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    header.hero {
        padding: 80px 0px; /* Reduce padding for mobile */
        display: block;
        height: 100%;
    }

    .hero h1 {
        font-size: 2rem;
        margin-bottom: 20px;
        margin-top: 10px;
        max-width: 75%;
        text-align: center;
        display: block;
        margin: 0 auto;
    }

    .hero p {
        font-size: 1rem;
        margin: 10px 20px;
        display: block;
    }

    .carousel {
        margin: 0 auto;
        max-width: 90%;
        display: block;
    }

    .discover-btn {
        text-align: center;
        margin: 20px auto; /* Ensure the button is centered and has proper spacing */
        display: block;
    }

    section.about {
        margin: 50px auto;
        max-width: 90%;
        display: block;
    }

    .about h2 {
        font-size: 1.5rem;
        display: block;
    }

    .about p {
        font-size: 1.2rem;
        display: block;
    }

    h3 {
        font-size: 1.5rem;
        margin: 10px 20px 10px 20px;
    }

    .gallery {
        padding: 10px;
    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .socials,
    .contact,
    .legal {
        margin: 20px 0;
    }

    .footer .contact {
        min-width: 100%;
    }

    footer ul {
        text-align: center;
    }
}
