.gallery-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Exactly 3 columns */
    grid-gap: 15px; /* Spacing between the images */
    padding: 20px;
    background-color: transparent;
}

.gallery-item {
    overflow: hidden; /* Prevents image overflow on hover */
    background-color: transparent;
}

.gallery-item img {
    width: 100%; /* Ensures the image fills the container */
    height: 350px; /* Fixed height */
    object-fit: cover; /* Ensures image is cropped to fit the area, maintaining aspect ratio */
    border-radius: 10px; /* Optional: Adds rounded corners */
    transition: transform 0.2s ease; /* Smooth hover effect */
}

.gallery-item img:hover {
    transform: scale(1.02); /* Slight zoom on hover, but no overflow */
}
