.carousel {
    margin-bottom: 50px; /* Spacing between the carousel and the next section */
}

.carousel img {
    width: 100%; /* Full width of the carousel */
    height: 400px; /* Fixed height for all images */
    object-fit: cover; /* Ensures images are cropped to fit the container without stretching */
    border-radius: 10px; /* Optional rounded corners */
    transition: transform 0.5s ease;
}

.carousel button {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

.carousel button:first-of-type {
    left: 10px;
}

.carousel button:last-of-type {
    right: 10px;
}
